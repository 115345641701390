@import 'settings';

$callout-border-secondary: none;

@import 'foundation-emails';

// @import 'template/invoice';

@import 'components/buttons';
@import 'components/tables';


/* UTILITES */

.box-shadow {
    box-shadow: -2px 2px 10px 0 rgba(50,50,50,.15);
    &-tight {
        box-shadow: -2px 2px 4px 0 rgba(24,24,24,.25);
    }
}

.text-center {
    text-align: center !important;
}

.text-dark-gray {
    color: $dark-gray;
}

.text-medium-gray {
    color: $medium-gray;
}

.text-light-gray {
    color: $light-gray;
}

.text-warning {
    color: $warning-color;
}

.text-alert {
    color: $alert-color;
}

.text-white {
    color: $white;
    & a:hover {
        color: #ffffff;
    }
}

.text-bold {
    font-weight: 700;
}

.text-light {
    font-weight: 300;
}

.text-h6 {
    font-size: $h6-font-size;
}

.rounded-circle {
    border-radius: 50% !important;
}

.text-small {
    @extend small;
}


/* CALLOUTS */

th.callout-inner.secondary {
    border-radius: 8px;
}
th.callout-inner.light-gray {
    background-color: $light-gray;
    border: none;
    border-radius: 8px;
}
th.callout-inner.dark-blue {
    background-color: $dark-blue;
    border: none;
    border-radius: 8px;
    color: $white;
}

th.callout-inner.coupon {
    border-radius: 0;
    padding: 15px;
    color: $white;
}

.coupon-dash {
    border-color: $alert-color;
    border-style: dashed;
    padding: 2px;
}


/* GLOBAL */

// table.body {
//     background: fixed no-repeat center url("/assets/img/julian-gentilezza-351164-unsplash.jpg");
// }