
.table-card {
    border-collapse: initial;
    border-right:solid 1px #dcd9d9;
    border-left:solid 1px #dcd9d9;
    border-top:solid 1px #dcd9d9;
    border-bottom:solid 4px #dcd9d9;
    border-radius:8px;
}
.table-card-dark {
    border-collapse: initial;
    border-right:solid 1px darken($dark-blue, 10%);
    border-left:solid 1px darken($dark-blue, 10%);
    border-top:solid 1px darken($dark-blue, 10%);
    border-bottom:solid 4px darken($dark-blue, 10%);
    border-radius:8px;
}

.table-information {
    tr {
        border-bottom: 1px solid #CACACA;
    }
    td {
        padding-top: 24px;
    }
}


@media only screen and (max-width: #{$global-breakpoint}) {
    table.body .table-information {
        width: $global-width-small !important;
    }
}


td {
    word-wrap: unset;
    hyphens: unset;
    -moz-hyphens: unset;
}