/**
 * Facebook button class
 */

table.button.facebook {
    table {
      td {
        background: #4267B2;
        color: $button-color;
        border: 0px solid #4267B2;
      }
  
      a {
        color: $button-color;
        border: 0 solid #4267B2;
      }
    }
  }

table.button.facebook:hover {
  table {
    td {
      background: lighten(#4267B2, 10%);
      color: $button-color;
    }

    a {
      border: 0 solid lighten(#4267B2, 10%);
    }
  }
}

table.button.facebook:hover {
    table {
      td a {
        color: $button-color;
      }
    }
  }
  
  table.button.facebook:active {
    table {
      td a {
        color: $button-color;
      }
    }
  }
  
  table.button.facebook {
    table {
      td a:visited {
        color: $button-color;
      }
    }
  }

/**
 * YouTube button class
 */

table.button.youtube {
    table {
      td {
        background: #d02525;
        color: $button-color;
        border: 0px solid #d02525;
      }
  
      a {
        color: $button-color;
        border: 0 solid #d02525;
      }
    }
  }

table.button.youtube:hover {
  table {
    td {
      background: lighten(#d02525, 10%);
      color: $button-color;
    }

    a {
      border: 0 solid lighten(#d02525, 10%);
    }
  }
}

table.button.youtube:hover {
    table {
      td a {
        color: $button-color;
      }
    }
  }
  
  table.button.youtube:active {
    table {
      td a {
        color: $button-color;
      }
    }
  }
  
  table.button.youtube {
    table {
      td a:visited {
        color: $button-color;
      }
    }
  }


// Home ASAP button

table.button.schedule {
  table {
    td {
      background: $white;
      border: 0px solid $white;
    }

    a {
      color: $alert-color !important;
      border: 0 solid $white;
    }
  }
}

table.button.schedule:hover {
  table {
    td {
      background: darken($white, 10%);
    }

    a {
      color: $alert-color !important;
      border: 0 solid darken($white, 10%);
    }
  }
}